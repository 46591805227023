import amayraaLogo from "../assets/logo/amyraaLogo.png";
import facebookLogo from "../assets/logo/facebook.png";
import youtubeLogo from "../assets/logo/youtube.png";
import catelogue1 from "../assets/img/catalogue/catalogue 1.png";
import catelogue2 from "../assets/img/catalogue/catalogue 2.png";
import catelogue3 from "../assets/img/catalogue/catalogue 3.png";
import catelogue4 from "../assets/img/catalogue/catalogue 4.png";
import extra1 from "../assets/img/extra/extra 1.png";
import extra2 from "../assets/img/extra/extra 2.png";
import extra3 from "../assets/img/extra/extra 3.png";
import extra4 from "../assets/img/extra/extra 4.png";

export const rectangleCardData = [
  {
    name: "catalogue",
    logo: amayraaLogo,
    bgColor: "#f4d1770c",
    btnText: "view",
    imgArr: [catelogue1, catelogue2, catelogue3, catelogue4],
    id: "catalogue1",
    linkTo: "https://amyraa.app/",
  },
  {
    name: "facebook",
    logo: facebookLogo,
    bgColor: "#1877f20a",
    btnText: "view",
    imgArr: [extra1, extra2, extra3, extra4],
    flexdir: "column",
    id: "catalogue2",
    linkTo: "https://www.facebook.com/profile.php?id=100083221792169",
  },
  {
    name: "youtube",
    logo: youtubeLogo,
    bgColor: "#f83e3016",
    btnText: "Subscribe",
    imgArr: [extra1, extra2, extra3, extra4],
    id: "catalogue3",
    linkTo: "https://www.youtube.com/@AdminAmyraa",
  },
];

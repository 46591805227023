import React from "react";
import Textbox from "../component/Textbox";
// import VivahLogo from "../assets/logo/vivah.png";
import AmyraaLogo from "../assets/logo/amyraa_logo.png";
import TopCardComponet from "../component/allBoxComponentCard/TopCardComponet";
import MiddleCardComponent from "../component/allBoxComponentCard/MiddleCardComponent";
import BottomCardComponent from "../component/allBoxComponentCard/BottomCardComponent";
const Vivah = () => {
  let props = {
    logo: AmyraaLogo,
    text: "Amyraa",
    fontWeight: "700",
    email: "admin@amyraa.app",
  };
  return (
    <div className="flex min-h-screen flex-col justify-center items-center gap-6 w-full py-[1.6rem] lg:py-0 px-[.5rem] lg:max-w-[1350px] text-primary-font-color lg:flex-row">
      <div className="w-full lg:w-[40%]">
        <Textbox {...props} />
      </div>
      <div className="flex w-full lg:w-min flex-col ">
        <TopCardComponet />
        <div className="flex w-full lg:w-min flex-col lg:flex-row-reverse lg:flex-none ">
          <MiddleCardComponent />
          <BottomCardComponent />
        </div>
      </div>
    </div>
  );
};

export default Vivah;

import instagramLogo from "../assets/logo/instagram.png";
import whatsappLogo from "../assets/logo/whatsapp 1.png";
import googleLogo from "../assets/logo/google.png";
import indiamartLogo from "../assets/logo/indiamart.png";
import twitterLogo from "../assets/logo/twitter.png";
import mapLogo from "../assets/logo/maps.png";
import playstore from "../assets/logo/play-store.webp";
import appstore from "../assets/logo/App-store.webp";
import webimg from "../assets/logo/web.png";

export const squareCardData = [
  {
    name: "instagram",
    logo: instagramLogo,
    bgColor: "#FFFFFF",
    btnText: "follow",
    linkTo: "https://www.instagram.com/amyraa.app?igsh=MTBocmFzd2owdjNhdw==",
    squareId: "square1",
  },
  {
    name: "whatsApp",
    logo: whatsappLogo,
    bgColor: "rgba(100, 177, 97, 0.09)",
    btnText: "chat now",
    linkTo: "https://api.whatsapp.com/send?phone=9320838762&text=Hello",
    squareId: "square2",
  },
  {
    name: "review us",
    boldTag: "on Google",
    logo: googleLogo,
    bgColor: "#FFFFFF",
    btnText: "Rate us",
    linkTo: "https://g.page/r/CZAhjZKAIuFWEAg/review",
    squareId: "square3",
  },
  {
    name: "IndiaMart",
    logo: indiamartLogo,
    bgColor: "#FFFFFF",
    btnText: "visit",
    linkTo: "https://www.vivahcreation.net/",
    squareId: "square4",
  },
  {
    name: "twitter",
    logo: twitterLogo,
    bgColor: "rgba(0, 0, 0, 0.03)",
    btnText: "follow",
    linkTo: "https://twitter.com/vivah_creation",
    squareId: "square5",
  },
  {
    name: "amyraa",
    logo: mapLogo,
    bgColor: "#FFFFFF",
    btnText: "locate",
    linkTo: "https://maps.app.goo.gl/Jv29LyJH6thWHWSP7",
    squareId: "square6",
  },
  {
    name: "Playstore",
    logo: playstore,
    bgColor: "#FFFFFF",
    btnText: "download",
    linkTo: "https://play.google.com/store/apps/details?id=com.amyra",
    squareId: "square7",
  },
  {
    name: "Appstore",
    logo: appstore,
    bgColor: "#FFFFFF",
    btnText: "download",
    linkTo: "https://apps.apple.com/in/app/amyraa/id6476032395",
    squareId: "square8",
  },
  {
    name: "Web",
    logo: webimg,
    bgColor: "#FFFFFF",
    btnText: "visit",
    linkTo: "https://amyraa.app",
    squareId: "square9",
  },
];

import React from "react";
import SquareCard from "../cardComponents/SquareCard";
import { squareCardData } from "../../data/squareCardData";
import RectangleCard from "../cardComponents/RectangleCard";
import { rectangleCardData } from "../../data/rectangleCardData";
const MiddleCardComponent = () => {
  return (
    <div className="flex flex-1 w-full ">
      <div className="flex flex-col w-1/2 flex-1 lg:flex-none">
        <SquareCard {...squareCardData[8]} />
        <SquareCard {...squareCardData[5]} />
      </div>
      <div className="flex flex-1">
        <RectangleCard {...rectangleCardData[1]} />
      </div>
    </div>
  );
};

export default MiddleCardComponent;
